import {
  keepPreviousData,
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { pickBy } from 'lodash'
import { useMemo } from 'react'
import { SourceWithEntityName, TWELVE_HOURS_MS } from '@netpurpose/types'
import { valueIsDefined } from '@netpurpose/utils'
import { apiConfig } from '../../config'
import {
  ApiError,
  Portfolio as BasePortfolio,
  CopyPortfolioRequest,
  CopyPortfolioResponse,
  PortfolioUpdate,
} from '../../generated/facts'
import { GeneratedFactApi } from '../../GeneratedApi'
import { getPaginationConfig, useUrlApiConnector } from '../../hooks/useUrlTableApiConnector'
import {
  CreatePortfolio,
  formatCreatePortfolioRequest,
  Portfolio,
  PortfolioType,
  reversePortfolioFieldMap,
  reversePortfolioSnapshotFieldMap,
  reverseSourceFieldMap,
  transformPortfolio,
  transformSource,
} from '../../models'
import { snakeToCamelKeys } from '../../utils'
import { useStreamDownload } from '../useStreamDownload'
import {
  ENTITY_BREAKDOWN_BY_FUNDS_QUERY_CACHE_KEY,
  ENTITY_DATA_BY_QUESTION_ID_QUERY_CACHE_KEY,
} from './useEntities'
import {
  PAGINATED_FOF_HOLDINGS_BY_COMPANY_QUERY_CACHE_KEY,
  PAGINATED_HOLDINGS_QUERY_CACHE_KEY,
} from './useHoldings'

export const usePaginatedPortfolios = ({
  perPage,
  useUrlSync,
  defaultParams,
  enabled = true,
}: {
  perPage?: number
  useUrlSync?: boolean
  defaultParams?: {
    type?: PortfolioType
    portfolio_id?: string[]
  }
  enabled?: boolean
}) => {
  const queryCacheKeyRoot = 'portfolios'

  const { queryString, filterConfig, initialPaginationConfig } = useUrlApiConnector<Portfolio>({
    tableToApiFieldMap: reversePortfolioFieldMap,
    urlKey: queryCacheKeyRoot,
    defaultParams,
    ...pickBy({ perPage, urlSyncEnabled: useUrlSync }, valueIsDefined),
  })

  const { data, ...rest } = useQuery({
    queryKey: [queryCacheKeyRoot, queryString],
    queryFn: () =>
      GeneratedFactApi.portfolios.listPortfolios({
        q: queryString,
      }),
    placeholderData: keepPreviousData,
    staleTime: apiConfig.defaultStaleTime,
    enabled,
  })

  const transformedPortfolios = useMemo(
    () =>
      data?.results ? data?.results.map((d) => transformPortfolio(snakeToCamelKeys(d))) : undefined,
    [data?.results],
  )

  const paginationConfig = getPaginationConfig({
    numResults: data?.total,
    paginationConfig: initialPaginationConfig,
  })

  return {
    ...rest,
    data: {
      ...data,
      results: transformedPortfolios,
    },
    filterConfig,
    paginationConfig,
  }
}

export const usePortfolio = ({ portfolioId }: { portfolioId: number | undefined | null }) => {
  const { data, ...rest } = useQuery({
    queryKey: ['portfolio', portfolioId],
    queryFn: () =>
      portfolioId
        ? GeneratedFactApi.portfolios.getPortfolioWithHoldings({ portfolioId })
        : undefined,
    enabled: !!portfolioId,
    staleTime: apiConfig.defaultStaleTime,
  })

  const transformedPortfolio = useMemo(
    () => (data ? transformPortfolio(snakeToCamelKeys(data)) : undefined),
    [data],
  )

  return {
    ...rest,
    data: transformedPortfolio,
  }
}

export const usePortfolioSources = ({
  defaultParams,
  portfolioId,
}: {
  defaultParams?: { [key: string]: string | number | string[] }
  portfolioId: number
}) => {
  const queryCacheKeyRoot = 'portfolioSources'

  const { queryString, filterConfig, initialPaginationConfig } =
    useUrlApiConnector<SourceWithEntityName>({
      tableToApiFieldMap: reverseSourceFieldMap,
      urlKey: queryCacheKeyRoot,
      defaultParams,
    })

  const { data, ...rest } = useQuery({
    queryKey: [queryCacheKeyRoot, queryString, portfolioId],

    queryFn: () =>
      portfolioId
        ? GeneratedFactApi.portfolios.listPortfolioSources({
            portfolioId,
            q: queryString,
          })
        : undefined,

    enabled: !!portfolioId && !!queryString,
    staleTime: TWELVE_HOURS_MS,
    placeholderData: keepPreviousData,
  })

  const transformedSources = useMemo(
    () =>
      data?.results ? data?.results.map((d) => transformSource(snakeToCamelKeys(d))) : undefined,
    [data?.results],
  )

  const paginationConfig = getPaginationConfig({
    numResults: data?.total,
    paginationConfig: initialPaginationConfig,
  })

  return {
    ...rest,
    data: {
      ...data,
      results: transformedSources,
    },
    filterConfig,
    paginationConfig,
  }
}

export const usePortfolioDisclosure = ({
  portfolioId,
  selectedMetricConfigId,
}: {
  portfolioId: number
  selectedMetricConfigId: string | null
}) => {
  const { data, isFetching } = useQuery({
    queryKey: ['portfolioDisclosure', portfolioId, selectedMetricConfigId],

    queryFn: () =>
      portfolioId && selectedMetricConfigId
        ? GeneratedFactApi.portfolios.getDisclosureData({
            portfolioId,
            metricsConfigId: selectedMetricConfigId,
          })
        : undefined,

    staleTime: TWELVE_HOURS_MS,
    enabled: !!portfolioId && !!selectedMetricConfigId,
  })

  return {
    data: snakeToCamelKeys(data),
    isFetching,
  }
}

export type PortfolioDisclosureData = ReturnType<typeof usePortfolioDisclosure>['data']
export type QuestionDisclosure = NonNullable<PortfolioDisclosureData>[number]
export type DisclosureYear = QuestionDisclosure['absoluteYears'][number]

const defaultUploadErrorMessage = 'An error occurred. Please check your file and try again.'

const invalidateAllPortfolioQueries = ({ queryClient }: { queryClient: QueryClient }) => {
  queryClient.invalidateQueries({
    queryKey: ['portfolio'],
  })
  queryClient.invalidateQueries({
    queryKey: ['portfolios'],
  })
  // Dashboard
  queryClient.invalidateQueries({
    queryKey: ['portfolioImpactData'],
  })
  // SDGs
  queryClient.invalidateQueries({
    queryKey: ['portfolioSDGAlignment'],
  })
  // Holdings
  queryClient.invalidateQueries({
    queryKey: [PAGINATED_HOLDINGS_QUERY_CACHE_KEY],
  })
  // Holdings by company
  queryClient.invalidateQueries({
    queryKey: [PAGINATED_FOF_HOLDINGS_BY_COMPANY_QUERY_CACHE_KEY],
  })
  // Data
  queryClient.invalidateQueries({
    queryKey: [ENTITY_DATA_BY_QUESTION_ID_QUERY_CACHE_KEY],
  })
  // Entity breaddown by funds
  queryClient.invalidateQueries({
    queryKey: [ENTITY_BREAKDOWN_BY_FUNDS_QUERY_CACHE_KEY],
  })
  // Disclosure
  queryClient.invalidateQueries({
    queryKey: ['portfolioDisclosure'],
  })
}

export const useUploadPortfolio = ({
  onSuccess,
  onError,
  xNpFeature,
  isFoFUpload = false,
}: {
  onSuccess: (token: string, portfolio: CreatePortfolio) => void
  onError: (err: string) => void
  xNpFeature?: string[]
  isFoFUpload?: boolean
}) => {
  const queryClient = useQueryClient()

  const upload = isFoFUpload ? 'uploadPortfolioWithFundHoldings' : 'uploadPortfolio'

  const { mutate: uploadPortfolio, isPending } = useMutation<
    { result_id: string },
    ApiError,
    CreatePortfolio,
    unknown
  >({
    mutationFn: (payload) =>
      GeneratedFactApi.portfolios[upload]({
        formData: formatCreatePortfolioRequest(payload),
        ...(xNpFeature ? { xNpFeature } : {}),
      }),
    onSuccess: ({ result_id }, payload) => {
      // NOTE: the upload could be a new snapshot for an existing portfolio
      invalidateAllPortfolioQueries({ queryClient })
      onSuccess(result_id, payload)
    },
    onError: (err: ApiError) =>
      // @ts-expect-error - err.body is unknown type
      onError(err.body?.msg || err.body?.detail || defaultUploadErrorMessage),
  })

  return {
    uploadPortfolio,
    isPending,
  }
}

export const useUpdatePortfolio = ({
  portfolioId,
  onSuccess,
  onError,
}: {
  portfolioId: number
  onSuccess?: () => void
  onError?: (err: string) => void
}) => {
  const queryClient = useQueryClient()

  const { mutate: updatePortfolio, isPending } = useMutation<
    BasePortfolio,
    ApiError,
    PortfolioUpdate,
    unknown
  >({
    mutationFn: (requestBody) =>
      GeneratedFactApi.portfolios.updatePortfolio({
        portfolioId,
        requestBody,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['portfolio', portfolioId],
      })
      queryClient.invalidateQueries({
        queryKey: ['portfolios'],
      })
      // Dashboard
      queryClient.invalidateQueries({
        predicate: (query) =>
          ['portfolioImpactData', portfolioId].every((key) => query.queryKey.includes(key)),
      })
      // SDGs
      queryClient.invalidateQueries({
        predicate: (query) =>
          ['portfolioSDGAlignment', portfolioId].every((key) => query.queryKey.includes(key)),
      })
      // Holdings
      queryClient.invalidateQueries({
        predicate: (query) =>
          [PAGINATED_HOLDINGS_QUERY_CACHE_KEY, portfolioId].every((key) =>
            query.queryKey.includes(key),
          ),
      })
      // Holdings by company
      queryClient.invalidateQueries({
        predicate: (query) =>
          [PAGINATED_FOF_HOLDINGS_BY_COMPANY_QUERY_CACHE_KEY, portfolioId].every((key) =>
            query.queryKey.includes(key),
          ),
      })
      // Data
      queryClient.invalidateQueries({
        predicate: (query) =>
          [ENTITY_DATA_BY_QUESTION_ID_QUERY_CACHE_KEY, portfolioId].every((key) =>
            query.queryKey.includes(key),
          ),
      })
      // Entity breaddown by funds
      queryClient.invalidateQueries({
        predicate: (query) =>
          [ENTITY_BREAKDOWN_BY_FUNDS_QUERY_CACHE_KEY, portfolioId].every((key) =>
            query.queryKey.includes(key),
          ),
      })
      // Disclosure
      queryClient.invalidateQueries({
        predicate: (query) =>
          ['portfolioDisclosure', portfolioId].every((key) => query.queryKey.includes(key)),
      })
      // Snapshots
      queryClient.invalidateQueries({
        queryKey: ['portfolioSnapshots', portfolioId],
      })
      onSuccess?.()
    },
    onError: (err: ApiError) =>
      // @ts-expect-error - err.body is unknown type
      onError(err.body?.msg || err.body?.detail || 'An error occurred. Please try again.'),
  })

  return {
    updatePortfolio,
    isPending,
  }
}

export const useCopyPortfolio = ({
  portfolioId,
  onSuccess,
}: {
  portfolioId: number
  onSuccess: () => void
}) => {
  const { mutate: copyPortfolio, isPending } = useMutation<
    CopyPortfolioResponse,
    ApiError,
    CopyPortfolioRequest,
    unknown
  >({
    mutationFn: (requestBody) =>
      GeneratedFactApi.portfolios.copyPortfolio({
        portfolioId,
        requestBody,
      }),
    onSuccess,
  })

  return {
    copyPortfolio,
    isPending,
  }
}

type UsePortfolioSDGAlignment = {
  portfolioId: number | undefined
  isWeighted: boolean
  includeEstimations: boolean
}

const getPortfolioSdgOutcomesSummary = ({
  portfolioId,
  isWeighted,
  includeEstimations,
}: UsePortfolioSDGAlignment) =>
  portfolioId
    ? GeneratedFactApi.portfolios.getPortfolioSdgOutcomesSummary({
        portfolioId,
        isWeighted,
        includeEstimations,
      })
    : undefined

export const usePortfolioSDGAlignmentOutcomes = ({
  portfolioId,
  isWeighted,
  includeEstimations,
  enabled,
}: UsePortfolioSDGAlignment & { enabled: boolean }) => {
  const queryCacheKeyRoot = 'portfolioSDGAlignmentOutcomes'

  const { data, ...rest } = useQuery({
    queryKey: [queryCacheKeyRoot, portfolioId, isWeighted, includeEstimations],
    queryFn: () => getPortfolioSdgOutcomesSummary({ portfolioId, isWeighted, includeEstimations }),
    enabled: enabled && !!portfolioId,
    staleTime: TWELVE_HOURS_MS,
  })

  const queryClient = useQueryClient()

  // Make sure the initial query has completed successfully.
  if (data) {
    // By prefetching the query with the inverse of the current isWeighted and includeEstimations value,
    // we can avoid two separate loading states if the AUM/Holdings tab is switched
    // before the data becomes stale.
    queryClient.prefetchQuery({
      queryKey: [queryCacheKeyRoot, portfolioId, !isWeighted, includeEstimations],
      queryFn: () =>
        getPortfolioSdgOutcomesSummary({
          portfolioId,
          isWeighted: !isWeighted,
          includeEstimations,
        }),
      staleTime: apiConfig.defaultStaleTime,
    })

    queryClient.prefetchQuery({
      queryKey: [queryCacheKeyRoot, portfolioId, isWeighted, !includeEstimations],
      queryFn: () =>
        getPortfolioSdgOutcomesSummary({
          portfolioId,
          isWeighted,
          includeEstimations: !includeEstimations,
        }),
      staleTime: apiConfig.defaultStaleTime,
    })
  }

  return {
    ...rest,
    data: snakeToCamelKeys(data),
  }
}

export type PortfolioSDGAlignmentOutcomes = NonNullable<
  ReturnType<typeof usePortfolioSDGAlignmentOutcomes>['data']
>

export const usePortfolioSDGAlignmentRevenueWeighted = ({
  portfolioId,
  enabled,
}: {
  portfolioId: number | undefined
  enabled: boolean
}) => {
  const queryCacheKeyRoot = 'portfolioSDGAlignmentRevenueWeighted'

  const { data, ...rest } = useQuery({
    queryKey: [queryCacheKeyRoot, portfolioId],
    queryFn: () =>
      portfolioId
        ? GeneratedFactApi.portfolios.getPortfolioSdgRevenueSummaryWeighted({ portfolioId })
        : undefined,
    enabled,
    staleTime: TWELVE_HOURS_MS,
  })

  return {
    ...rest,
    data: snakeToCamelKeys(data),
  }
}

export type PortfolioSDGAlignmentRevenueWeighted = NonNullable<
  ReturnType<typeof usePortfolioSDGAlignmentRevenueWeighted>['data']
>

export const usePortfolioSDGAlignmentRevenueUnweighted = ({
  portfolioId,
  enabled,
}: {
  portfolioId: number | undefined
  enabled: boolean
}) => {
  const queryCacheKeyRoot = 'portfolioSDGAlignmentRevenueUnweighted'

  const { data, ...rest } = useQuery({
    queryKey: [queryCacheKeyRoot, portfolioId],
    queryFn: () =>
      portfolioId
        ? GeneratedFactApi.portfolios.getPortfolioSdgRevenueSummaryUnweighted({ portfolioId })
        : undefined,
    enabled,
    staleTime: TWELVE_HOURS_MS,
  })

  return {
    ...rest,
    data: snakeToCamelKeys(data),
  }
}

export type PortfolioSDGAlignmentRevenueUnweighted = NonNullable<
  ReturnType<typeof usePortfolioSDGAlignmentRevenueUnweighted>['data']
>

export const useExportPortfolio = ({
  portfolio,
  selectedMetricConfigId,
  requestPortfolioExport,
}: {
  portfolio: { id: number; name: string }
  selectedMetricConfigId: string
  requestPortfolioExport: (
    resultId: string,
    portfolioId: number,
    portfolioName: string,
    metricConfigId: string,
  ) => void
}) =>
  useMutation({
    mutationFn: () =>
      GeneratedFactApi.portfolios.exportPortfolio({
        requestBody: {
          portfolio_id: portfolio.id,
          export_options: {
            metrics_config_id: selectedMetricConfigId,
            should_send_email: true,
          },
        },
      }),
    onSuccess: ({ result_id }) =>
      requestPortfolioExport(result_id, portfolio.id, portfolio.name, selectedMetricConfigId),
  })

export const useExportPortfolioPAIs = ({
  portfolioId,
  portfolioName,
  requestPortfolioPAIsExport,
}: {
  portfolioId: number
  portfolioName: string
  requestPortfolioPAIsExport: (resultId: string, portfolioId: number, portfolioName: string) => void
}) =>
  useMutation({
    mutationFn: () =>
      GeneratedFactApi.portfolios.exportPortfolioPai({
        requestBody: {
          portfolio_id: portfolioId,
          export_options: { should_send_email: true },
        },
      }),
    onSuccess: ({ result_id }) => requestPortfolioPAIsExport(result_id, portfolioId, portfolioName),
  })

export const useExportPortfolioSDGs = ({
  portfolioId,
  portfolioName,
  requestPortfolioSDGsExport,
}: {
  portfolioId: number
  portfolioName: string
  requestPortfolioSDGsExport: (resultId: string, portfolioId: number, portfolioName: string) => void
}) =>
  useMutation({
    mutationFn: () =>
      GeneratedFactApi.portfolios.exportPortfolioSdg({
        requestBody: {
          portfolio_id: portfolioId,
          export_options: { should_send_email: true },
        },
      }),
    onSuccess: ({ result_id }) => requestPortfolioSDGsExport(result_id, portfolioId, portfolioName),
  })

export const useExportPortfolioSDGRevenue = ({
  portfolioId,
  portfolioName,
  requestPortfolioSDGRevenueExport,
}: {
  portfolioId: number
  portfolioName: string
  requestPortfolioSDGRevenueExport: (
    resultId: string,
    portfolioId: number,
    portfolioName: string,
  ) => void
}) =>
  useMutation({
    mutationFn: () =>
      GeneratedFactApi.portfolios.exportPortfolioSdgRevenue({
        requestBody: {
          portfolio_id: portfolioId,
          export_options: { should_send_email: true },
        },
      }),
    onSuccess: ({ result_id }) =>
      requestPortfolioSDGRevenueExport(result_id, portfolioId, portfolioName),
  })

export const useExportPortfolioDashboard = ({
  portfolioId,
  portfolioName,
  metricConfigId,
  metricConfigName,
}: {
  portfolioId: number
  portfolioName: string
  metricConfigId: string
  metricConfigName: string
}) => {
  return useStreamDownload({
    url: `${apiConfig.factsHost}/api/v1/portfolios/dashboard/export`,
    downloadName: `${portfolioName} - Dashboard - ${metricConfigName}`,
    body: JSON.stringify({
      portfolio_id: portfolioId,
      export_options: { metrics_config_id: metricConfigId },
    }),
  })
}

export const useExportPortfolioData = ({
  portfolioId,
  portfolioName,
  theme,
  questionId,
  questionName,
}: {
  portfolioId: number
  portfolioName: string
  theme: string
  questionId: number
  questionName: string
}) => {
  return useStreamDownload({
    url: `${apiConfig.factsHost}/api/v1/portfolios/data/export`,
    downloadName: `${portfolioName} - Data - ${theme} - ${questionName}`,
    body: JSON.stringify({
      portfolio_id: portfolioId,
      question_id: questionId,
    }),
  })
}

export const useExportPortfolioHoldings = ({
  portfolioId,
  portfolioName,
  snapshotId,
}: {
  portfolioId: number
  portfolioName: string
  snapshotId: number | undefined
}) => {
  return useStreamDownload({
    url: `${apiConfig.factsHost}/api/v1/holdings/export`,
    downloadName: `${portfolioName} - Holdings${snapshotId ? ' - Snapshot' + snapshotId : ''}`,
    body: JSON.stringify({
      portfolio_id: portfolioId,
      snapshot_id: snapshotId,
    }),
  })
}

export const useDeletePortfolio = ({ onError }: { onError: (err: string) => void }) => {
  const { mutate: deletePortfolio, isPending } = useMutation<
    unknown,
    ApiError,
    { portfolioId: number },
    unknown
  >({
    mutationFn: ({ portfolioId }) =>
      GeneratedFactApi.portfolios.deletePortfolio({
        portfolioId,
      }),
    onError: (err: ApiError) =>
      // @ts-expect-error - err.body is unknown type
      onError(err.body?.msg || err.body?.detail || 'An error occurred. Please try again.'),
  })

  return {
    deletePortfolio,
    isPending,
  }
}

export const useListPortfolioSnapshots = ({
  portfolioId,
  enabled = true,
}: {
  portfolioId: number | undefined
  enabled?: boolean
}) => {
  const queryCacheKeyRoot = 'portfolioSnapshots'

  const { queryString } = useUrlApiConnector({
    tableToApiFieldMap: reversePortfolioSnapshotFieldMap,
    urlKey: queryCacheKeyRoot,
    defaultSortBy: 'valuationDate',
    defaultSortReverse: true,
    urlSyncEnabled: false,
  })

  return useQuery({
    queryKey: [queryCacheKeyRoot, portfolioId, queryString],
    queryFn: () =>
      portfolioId
        ? GeneratedFactApi.portfolios
            .listPortfolioSnapshots({ portfolioId, q: queryString })
            .then(snakeToCamelKeys)
        : undefined,
    enabled: !!portfolioId && enabled,
    staleTime: TWELVE_HOURS_MS,
  })
}
