import {
  Body_upload_portfolio,
  Body_upload_portfolio_with_fund_holdings,
} from '../../generated/facts'
import { formatDateForBackend } from '../datetime'

export type CreatePortfolio = {
  name: string
  type: 'benchmark' | 'fund' | 'private'
  currency: string
  totalValue: number
  valuationDate: Date
  file: File
}

export const formatCreatePortfolioRequest = (
  portfolio: CreatePortfolio,
): Body_upload_portfolio | Body_upload_portfolio_with_fund_holdings => ({
  portfolio_name: portfolio.name,
  total_value: portfolio.totalValue,
  valuation_date: formatDateForBackend(portfolio.valuationDate),
  portfolio_type: portfolio.type,
  identifier_col: 'ISIN',
  weight_col: 'Weight',
  name_col: 'Name',
  portfolio_file: portfolio.file,
})
